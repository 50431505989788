<template>
    <div>
        <div class="top">
            <div class="login">
                <input ref="name" v-model="musicName" @keydown.enter="onSearch" />
                <a href="#" @click="onAdd" role="button">新增</a>
                <a href="#" @click="onSearch" role="button">查找</a>
                <!--                <b-button type="is-primary" label="登录" @click="isActive = !isActive" />-->
            </div>
            <div class="bl-cont">
                <div class="logo-cont">
                    <img class="logo" src="@/assets/image/logo.svg" alt="Blove logo" />
                </div>
            </div>
            <div class="bl-cont logo-title">
                <h1>Blove For You</h1>
            </div>
            <div class="bl-cont">
                <div class="top-text">
                    “Blove For You”
                    一种对生活细节的深情体会与感恩，它教会了我们在平凡的日常中发现非凡的美好。清晨的微笑、忙碌的奔波、不懈的坚持，正是我们对生活的热爱与执着。面对任何风浪，我们勇敢前行。正是这些点点滴滴，留下了我们走过的痕迹，铸造了人生的不平凡。
                </div>
            </div>
            <div ref="platformCont" class="round_div_all">
                <div v-for="item in platforms" :key="item.title">
                    <div class="round_div">
                        <div class="r-s01">
                            <a :href="item.url" target="_blank">
                                <i class="bl-icon icon-platform" :class="`bl-icon-${item.icon}`"></i>
                            </a>
                        </div>
                        <div class="r-s02">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content">
            <div ref="squareCont" class="mian01">
                <div v-for="item in sites" :key="item.name">
                    <div class="square_div">
                        <a :href="item.url" target="_blank">
                            <div class="bl-cont squareCont">
                                <div class="s-s01">
                                    <i v-if="!item.logo" class="bl-icon icon-link" :class="`bl-icon-website1`"></i>
                                    <i
                                        v-else-if="item.logoType === 'FONT'"
                                        class="bl-icon icon-link"
                                        :class="`bl-icon-${item.logo}`"
                                    ></i>
                                    <i
                                        v-else
                                        class="bl-icon icon-link"
                                        :style="{
                                            width: '1em',
                                            height: '1em',
                                            borderRadius: '50%',
                                            backgroundImage: `url(${item.logo})`,
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat'
                                        }"
                                    ></i>
                                </div>
                                <div class="bl-cont">
                                    <div class="s-s02">{{ item.name }}</div>
                                    <div class="s-s03">{{ item.theme }}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <footer class="down">
                <span class="f82">安全 | 服务条款 | 使用规范 | 客服中心 | 隐私保护指引</span><br />
                <span class="f75">Copyright © 1998-2024 All Rights Reserved.</span>
            </footer>
            <div class="banquan">
                <!--                <audio ref="audioPlayer" src="@/assets/music/凡人诀.mp3"></audio>-->
                <audio ref="audioPlayer" :src="musics[0]"></audio>
                <div>
                    <i class="music-icon bl-icon bl-icon-Music-1" @click="onModel"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-previous-fill" @click="onPrev"></i>
                    <i v-if="isPlaying" class="music-action-icon bl-icon bl-icon-pause1" @click="onPause"></i>
                    <i v-else class="music-action-icon bl-icon bl-icon-play-arrow-fill" @click="onStart"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-next-fill" @click="onNext"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { request } from '@/blvue/request';

export default {
    name: 'BlMusic',
    props: {},
    computed: {},
    watch: {},
    data() {
        return {
            platforms: [
                {
                    title: 'Android',
                    icon: 'android',
                    url: ''
                },
                {
                    title: 'IOS',
                    icon: 'macos',
                    url: ''
                },
                {
                    title: 'Windows',
                    icon: 'Windows',
                    url: ''
                },
                {
                    title: 'Harmony OS',
                    icon: 'macos',
                    url: ''
                }
            ],
            sites: [],
            isPlaying: false,
            musics: [],
            currentIndex: 0,
            isActive: true,
            models: { loop: '⟴', single: '⟲', random: '⤮' },
            modelKey: 'loop',
            musicName: ''
        };
    },
    mounted() {
        const self = this,
            { $refs } = self;
        $refs.audioPlayer.addEventListener('ended', function () {
            self.onNext();
        });
        self.loadAllMusic();
        self.loadAllSite();
    },
    methods: {
        async loadAllMusic() {
            const self = this,
                url = '/master/music/listMusics';
            const result = await request.get(url);
            console.warn(result);
            if (result.status === 200 && result.data.length > 0) {
                self.musics.length = 0;
                result.data.forEach((item) => {
                    self.musics.push(item.url);
                });
                console.warn('最新歌单', self.musics);
            }
        },
        async loadAllSite() {
            const self = this,
                url = '/master/site/list';
            const result = await request.get(url);
            if (result.status === 200 && result.data.length > 0) {
                self.sites = result.data || [];
            }
        },
        onAdd() {
            this.saveMusic();
        },
        onSearch() {
            if (this.musicName !== undefined) {
                this.loadMusic();
            }
        },
        async loadMusic() {
            const self = this,
                url = '/master/music/getMusic';
            const result = await request.get(url, { name: self.musicName });
            console.warn(result);
            const music = result.data;
            if (music !== null) {
                self.musicName = music.name;
                if (music.url !== null) {
                    self.onNext(music.url);
                }
            }
        },
        async saveMusic() {
            const self = this,
                url = '/master/music/save';
            const result = await request.post(url, { name: self.musicName });
            console.warn(result);
            if (result.status === 200 && result.data !== null) {
                if (result.data.url !== null) {
                    self.onNext(result.data.url);
                }
            }
        },
        onPlayOrPause() {
            const audio = this.$refs.audioPlayer;
            if (this.isPlaying) {
                audio.pause();
            } else {
                this.musicName = this._getFileName(audio.src);
                audio.play().catch((error) => {
                    console.error('播放失败:', error);
                });
            }
            this.isPlaying = !this.isPlaying;
        },
        onPause() {
            if (this.isPlaying) {
                this.onPlayOrPause();
            }
        },
        onStart() {
            if (!this.isPlaying) {
                this.onPlayOrPause();
            }
        },
        onNext(musicSrc) {
            const self = this,
                { $refs } = self;
            let currentSrc;
            if (typeof musicSrc === 'string') {
                currentSrc = musicSrc;
            } else {
                if (!self.isPlaying) {
                    self.currentIndex = 0;
                } else {
                    self.currentIndex++;
                }
                if (self.currentIndex >= self.musics.length) {
                    self.currentIndex = self._getRandomInt(0, self.musics.length - 2);
                }
                currentSrc = self.musics[self.currentIndex];
            }

            $refs.audioPlayer.src = currentSrc;
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        onPrev() {
            const self = this,
                { $refs } = self;
            if (!self.isPlaying) {
                self.currentIndex = 0;
            } else {
                self.currentIndex--;
            }
            if (self.currentIndex < 0) {
                self.currentIndex = self._getRandomInt(1, self.musics.length - 1);
            }
            $refs.audioPlayer.src = self.musics[self.currentIndex];
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        onModel() {
            const self = this;
            let keys = Object.keys(self.models);
            for (let i = 0; i < keys.length; i++) {
                if (self.modelKey === keys[i]) {
                    if (i === keys.length - 1) {
                        self.modelKey = keys[0];
                    } else {
                        self.modelKey = keys[i + 1];
                    }
                    break;
                }
            }
            let model = self.models[self.modelKey];
            const styleSheet = document.styleSheets[0];
            const rules = styleSheet.rules || styleSheet.cssRules;
            for (let i = 0; i < rules.length; i++) {
                if (rules[i].selectorText === '.music-icon::after') {
                    rules[i].style.content = `"${model}"`;
                }
            }
        },
        getImgStyle(icon) {
            let url = require(icon);
            console.warn(url);
            return {
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundImage: `url(${require(`${icon}`)})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            };
        },
        _getRandomInt(min, max) {
            min = Math.ceil(min); // 向上取整
            max = Math.floor(max); // 向下取整
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        _getFileName(url) {
            const decodedURL = decodeURIComponent(url);
            let fileName = decodedURL.substring(decodedURL.lastIndexOf('/') + 1);
            fileName = fileName.substring(0, fileName.lastIndexOf('.'));
            return fileName;
        }
    }
};
</script>
<style scoped>
@import '@/assets/css/home.css';
</style>
